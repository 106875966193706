// Here you can add other styles

.clearfix {
    overflow: auto;
}
.float-right{
    float: right;
}
.inline-alert{
    color: red;
    font-size: 0.8em;
}
.marker{
    position: absolute;
    top: 50%;
    left: 50%;    
    border-radius: 50% 50% 50% 0;
    border: 4px solid red;
    transform: rotate(-45deg) scale(3) translate(50%,-50%);
}
.small{
    transform: scale(0.8);
    margin-left: 0px;
    margin-right: 0px
}
.card{
    box-shadow: 4px 4px 10px gray;
    background-color: white;
    &:hover{
        box-shadow: 8px 8px 20px black;
        transition: all .4s ease-in;
    }
}
.package{
    box-shadow: none !important;
    border-style: none;
    border-radius: 10px;
    height: 500px;
    margin-bottom: 50px;
    text-align: center;
    transition: transform 1s cubic-bezier(.11,-1.50,.11,1.50); 
}

.brand-item-image{
    width: 100%;
    height: 150px;
    object-fit: contain;
}


